@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #f1f3f6;
  font-family: 'Roboto', sans-serif;
}

/* hover navbar dropdown arrow */
.arrow_down {
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-bottom: 11px solid #fff;
}

/* hover navbar dropdown arrow */

/* login signup sidebar bg image */
.loginSidebar {
  /* background-image: url("https://static-assets-web.flixcart.com/www/linchpin/fk-cp-zion/img/login_img_c4a81e.png"); */
  background-image: url("assets/images/logo.png");
  background-position: center 85%;
  background-repeat: no-repeat;
}

/* login signup sidebar bg image */

/* CSS */
.slider-container {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  /* Hide scrollbar for better appearance */
}

.slider-item {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  /* Adjust the height as needed */
  scroll-snap-align: start;
  margin-right: 10px;
  /* Adjust the spacing between items */
}

/* Hide scrollbar for Firefox */
.slider-container::-webkit-scrollbar {
  display: none;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr auto;
  /* Three equal columns */
  grid-gap: 20px;
  /* Gap between columns and rows */
  border-radius: 8px;
}

.grid-item {
  padding: 5px;
  border-radius: 4px;
}

.grid-container-3 {
  display: grid;
  grid-template-columns: 60px auto 120px;
  width: 100%;
  /* Two equal columns */
  gap: 20px;
  /* Gap between columns and rows */
}