 .qr{
    align-items: center;
    text-align: center;
    height : 35%;
    width : 35%;
    margin: auto 40% auto auto ;
}

.proofWindow{
    /* background: #fff; */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.5)); /* Transparent gradient from top to bottom */
    border-radius: 7px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    padding: 2%;
    width: 40%;
    height: 40%;

    
    border-radius: 7px;
    box-shadow: 0 2px 5px #ccc;
    padding: 2% 2% 2% 1%;
    width: 60%;
    margin: auto 25% auto auto ;
    /* float: center; */
    /* z-index: 10;     */
    align-items: center;
}
.proofWindow p {
    border-radius: 0.2rem;
}
/*
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }
  
  .popup img {
    max-width: 100%;
    max-height: 400px;
    margin-bottom: 20px;
  }
  
  .popup button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .popup button:hover {
    background-color: #0056b3;
  } */
  
/* transaction no */
body {
    height: 100vh;
    display: grid;
    font-family: Roboto;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
  }
  
  * {
    box-sizing: border-box;
  }
  
  .inp {
    position: relative;
    margin: auto;
    width: 100%;
    max-width: 380px;
    border-radius: 3px;
    overflow: hidden;
  }
  
  .inp .label {
    position: absolute;
    top: 50%;
    left: 12px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 500;
    transform-origin: 0 0;
    transform: translate3d(0, -50%, 0) scale(1);
    transition: all 0.2s ease;
    pointer-events: none;
  }
  
  .inp .focus-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.05);
    z-index: -1;
    transform: scaleX(0);
    transform-origin: left;
  }
  
  .inp input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    border: 0;
    font-family: inherit;
    padding: 16px 12px 0 12px;
    height: 56px;
    font-size: 16px;
    font-weight: 400;
    background: rgba(0, 0, 0, 0.02);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.3);
    color: #000;
    transition: all 0.15s ease;
  }
  
  .inp input:hover {
    background: rgba(0, 0, 0, 0.04);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.5);
  }
  
  .inp input:not(:placeholder-shown) + .label,
  .inp input:focus + .label {
    color: #0077FF; /* Blue color for the label text */
    transform: translate3d(0, -120%, 0) scale(0.75); /* Adjust the translate value as needed */
  }
  
  .inp input:focus {
    background: rgba(0, 0, 0, 0.05);
    outline: none;
    box-shadow: inset 0 -2px 0 #0077FF;
  }
  
  .inp input:focus + .label + .focus-bg {
    transform: scaleX(1);
    transition: all 0.1s ease;
  }
  